@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Avenir', Sans-Serif;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

@layer utilities {
  .safe-height {
    height: calc(100vh - env(safe-area-inset-bottom));
  }
}
